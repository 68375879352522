import React, { useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";

import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/esm/Button";
import Table from "react-bootstrap/Table";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Footer from "../Footer";
import Header from "../Header";

const Cart = ({ cart }) => {
  const [firstName, setFirstName] = useState("");

  const [qty, setQty] = useState([1]);

  const [items, setItems] = useState([]);

  const [price, setPrice] = useState(null);
  const [pcName, setPcName] = useState();

  useEffect(() => {
    const user = sessionStorage.getItem("accessToken")
      ? jwtDecode(sessionStorage.getItem("accessToken")).userInfo
      : false;
    setFirstName(user.firstName);

    setItems(cart);
  }, []);

  const title = (price) => {
    if (price < 60000) {
      return "Fusion Gaming Elite PC";
    } else if (price > 59999 && price < 120000) {
      return "Fusion Gaming Pro PC";
    } else if (price > 129999) {
      return "Fusion Gaming Master PC";
    }
  };

  return (
    <div className="bg-black pb-5">
      <Container className="bg-black">
        <Row style={{ height: "55vh" }}>
          <Col className="col-lg-2 mt-5">
            <Row className="p-4 text-light border-danger border rounded ">
              Hello {firstName} !
            </Row>
          </Col>
          <Col className="col-lg-9 m-5 border border-light rounded">
            <Row className="h3 m-3 mt-3 text-danger">
              <Col className="lg-9">Your Items</Col>
            </Row>
            <Row className="h5 mt-5 px-3 text-light">
              <Col>
                {items.length !== 0 ? (
                  <Table striped bordered hover variant="dark">
                    <thead>
                      <tr>
                        <th className="h5 text-danger text-center">S.No.</th>
                        <th className="h5 text-danger text-center">Items</th>
                        <th className="h5 text-danger text-center">Quantity</th>
                        <th className="h5 text-danger text-center">Price(₹)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {items.map((item, index) => (
                        <tr key={index}>
                          <td className="small text-center">{index + 1}</td>
                          <td className="small">
                            <Row>
                              <Col className="">
                                <span className="text-light">
                                  {title(item.price)}
                                </span>
                                <br></br>
                                <small className="text-secondary">
                                  {item.processor}/{item.gpuModel}/
                                  {item.ramSize}/{item.cabinetModel}
                                </small>
                              </Col>
                              <Col
                                className="col-lg-1"
                                style={{ height: "40px" }}
                              >
                                <Row className="me-3">
                                  <Button
                                    variant="outline-danger"
                                    onClick={(e) =>
                                      setQty((qty) => qty - 1 + 2)
                                    }
                                    style={{ height: "20px" }}
                                    size="sm"
                                  >
                                    +
                                  </Button>
                                </Row>
                                <Row className="me-3">
                                  <Button
                                    variant="outline-danger"
                                    onClick={(e) =>
                                      qty === 1 ? qty : setQty((qty) => qty - 1)
                                    }
                                    style={{ height: "20px" }}
                                    size="sm"
                                  >
                                    -
                                  </Button>
                                </Row>
                              </Col>
                            </Row>
                          </td>
                          <td className="small text-center">{qty}</td>
                          <td className="small text-center">
                            {item.price * qty}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  "No items in cart!"
                )}
              </Col>
            </Row>
            <Row className="justify-content-end align-items-end">
              <Col className="col-lg-2">
                <Button variant="danger" size="lg">
                  <span className="h5">Checkout</span>
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Cart;
