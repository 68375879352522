import React, { useState, useEffect } from "react";
import Header from "../Header";
import { jwtDecode } from "jwt-decode";
import axios from "../../api/axios";
import { Link, useNavigate } from "react-router-dom";

import { collection, getDocs, updateDoc, doc } from "firebase/firestore";
import { useAuth } from "../../contexts/MyContext";
import { db } from "../../firebase";

import Container from "react-bootstrap/Container";
import Footer from "../Footer";
import Button from "react-bootstrap/esm/Button";
import Table from "react-bootstrap/Table";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const DashboardSavedAddresses = () => {
  const navigate = useNavigate();
  const collectionRef = collection(db, "Users");
  const { uid, user, setAuthContext, setUser, setUid } = useAuth();
  const url = "https://fusion-backend.onrender.com";

  const [addresses, setAddresses] = useState([]);
  const [firstName, setFirstName] = useState("");

  useEffect(() => {
    const getAddress = async (uid) => {
      try {
        const res = await axios.post(
          url + "/fetchaddresses",
          { id: uid },
          { withCredentials: true }
        );
        setAddresses(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    getAddress(uid);
  }, []);

  const handleSubmit = (e) => {
    setAuthContext(null);
    setUser(null);
    setUid(null);
    navigate("/", { replace: true });
  };

  return (
    <div className="bg-black pb-5">
      <Container className="bg-black">
        <Row style={{ height: "55vh" }}>
          <Col className="col-lg-2 mt-5">
            <Row className="p-4 text-light border-danger border rounded ">
              Hello {user} !
            </Row>
            <Row className="p-4 text-light mt-5 border-danger border rounded">
              <Row className="justify-content-center mt-5">
                <Col className="h5 px-1 text-danger col-12">
                  <Link
                    className="link-light link-underline-opacity-0"
                    to="/dashboard/personal"
                  >
                    Profile Info
                  </Link>
                </Col>
              </Row>
              <Row className="justify-content-center mt-1">
                <Col className="h5 px-1 text-light col-12">
                  <Link
                    className="link-danger link-underline-opacity-0"
                    to="/dashboard/addresses"
                  >
                    Saved Addresses
                  </Link>
                </Col>
              </Row>
              <Row className="justify-content-center mt-1">
                <Col className="h5 px-1 text-light col-12">
                  <Link
                    className="link-light link-underline-opacity-0"
                    to="/dashboard/orders"
                  >
                    Your Orders
                  </Link>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col className="h5 mt-5 px-4 text-light col-12">
                  <Button variant="danger" onClick={handleSubmit} size="sm">
                    <span className="h5">LOG OUT</span>
                  </Button>
                </Col>
              </Row>
            </Row>
          </Col>
          <Col className="col-lg-9 m-5 border border-light rounded">
            <Row className="h3 m-3 mt-3 text-danger">
              <Col className="lg-9">
                Saved Addresses (
                <Link
                  className="link-secondary h5 link-underline-opacity-0"
                  to="/dashboard/addresses/update"
                >
                  Add/Update Addresses
                </Link>
                )
              </Col>
            </Row>
            <Row className="h5 mt-5 px-3 text-light">
              <Col>
                {addresses[0] ? (
                  <Table striped bordered hover variant="dark">
                    <thead>
                      <tr>
                        <th className="h5 text-danger text-center">S.No.</th>
                        <th className="h5 text-danger text-center">Address</th>
                      </tr>
                    </thead>
                    <tbody>
                      {addresses.map((address, i) => (
                        <tr>
                          <td className="small text-center">{i + 1}</td>
                          <td className="small">{address.address}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  "No Addresses Saved!"
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default DashboardSavedAddresses;
