import React, { useState } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";

import RequireAuth from "./components/RequireAuth";

import Configurator from "./Configurator";
import Unauthoriszed from './components/Unauthorized'
import Home from "./Home";
import AboutUs from "./AboutUs";
import Login from "./Login2";
import CusDetails from "./CusDetails";
import Register from "./Register";
import Otp from "./Otp";
import DashboardPersonalInformation from "./components/dashboard/DashboardPersonalInformation";
import DashboardOrders from "./components/dashboard/DashboardOrders";
import DashboardSavedAddresses from "./components/dashboard/DashboardSavedAddresses";
import UpdatePersonal from "./components/dashboard/UpdatePersonal";
import UpdateAddresses from "./components/dashboard/UpdateAddresses";
import Cart from "./components/cart/Cart";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Whatsapp from "./components/Whatsapp";
import Container from "react-bootstrap/esm/Container";
import { Details } from "@mui/icons-material";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import  {MyProvider, useAuth } from "./contexts/MyContext";
import ForgotPassword from "./ForgotPassword";

const App = () => {
  const [cart, setCart] = useState([]);

  const addToCart = (item) => {
    setCart((prevItems) => [...prevItems, item]);
  };
  console.log(cart);

  return (
    <div className="bg-black">
      
      <Container className="bg-black pb-5">
      <MyProvider>
        <Whatsapp />
        <Header cart={cart} />
        <ToastContainer />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route
            path="/config"
            element={<Configurator addToCart={addToCart} />}
          />
          <Route path="/login" element={<Login />} />
          <Route path="/CusDetails" element={<CusDetails />} />
          <Route path="/register" element={<Register />} />
          <Route path="/cart" element={<Cart cart={cart} />} />
          <Route path="*" element={<Home />} />
          <Route path="/unauthorized" element={<Unauthoriszed />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route element={<RequireAuth />}>
            <Route path="/dashboard/personal/otp" element={<Otp />} />
            <Route
              path="/dashboard/personal"
              element={<DashboardPersonalInformation />}
            />
            <Route
              path="/dashboard/personal/update"
              element={<UpdatePersonal />}
            />
            <Route
              path="/dashboard/addresses"
              element={<DashboardSavedAddresses />}
            />
            <Route
              path="/dashboard/addresses/update"
              element={<UpdateAddresses />}
            />
            <Route path="/dashboard/orders" element={<DashboardOrders />} />
          </Route>
        </Routes>
        <Footer />
        </MyProvider>
      </Container>
    </div>
  );
};

export default App;
