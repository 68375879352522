import { useLocation, Outlet, Navigate } from "react-router-dom";
import { useAuth } from "../contexts/MyContext";

const RequireAuth = () => {
    const {location} = useLocation();
    const {authContext} = useAuth();
    return (
        authContext !== null ? <Outlet/>: <Navigate to='/login' state={{from: location}} replace/> 
    )
}

export default RequireAuth;