import React, { useState, useEffect } from "react";

import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/esm/Button";
import axios from "axios";

import { collection, getDocs, updateDoc, doc } from "firebase/firestore";
import { db } from "../../firebase";

import toast from "react-hot-toast";
import { useAuth } from "../../contexts/MyContext";
import { useNavigate } from "react-router-dom";

import Header from "../Header";
import Footer from "../Footer";

const UpdatePersonal = () => {
  const url = "https://fusion-backend.onrender.com";
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState("");
  const [verified, setVerified] = useState(0);
  const { uid } = useAuth();
  const [userId, setUserId] = useState("");

  const [status, setStatus] = useState(null);

  const collectionRef = collection(db, "Users");
  const navigate = useNavigate();

  const updateUser = () => async () => {
    try {
      const res = await axios.post(
        url + "/updateuser",
        {
          userId: uid,
          firstName,
          lastName,
          number,
          email,
          dob,
          gender,
          verified,
        },
        { withCredentials: true }
      );
      toast.success("User updated Successfully", {
        position: "top-center",
      });
      navigate("/dashboard/personal", { replace: true });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const getUser = async (uid) => {
      try {
        const res = await axios.post(
          url + "/fetchuser",
          { userId: uid },
          { withCredentials: true }
        );
        setFirstName(res.data.firstName);
        setLastName(res.data.lastName);
        setNumber(res.data.number);
        setEmail(res.data.email);
        setGender(res.data.gender);
        setDob(res.data.dob);
        setVerified(res.data.verified);
      } catch (err) {
        console.log(err);
      }
    };
    getUser(uid);
  }, []);

  return (
    <div className="bg-black pb-5">
      <Container className="bg-black">
        <Row className="justify-content-center mt-5">
          <Col className="h1 text-center text-light col-12">
            Update Personal Information
          </Col>
        </Row>
        <Row className="justify-content-center mt-1">
          <Col className="h5 text-center text-danger col-12">{email}</Col>
        </Row>
        <Row className="justify-content-center">
          <Col className="h5 text-center text-secondary col-12">
            <small>Secure Data Entry</small>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col className="h5 mt-5 text-light col-8 col-lg-4">
            <Form.Group className="mb-3">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                id="firstName"
                onChange={(e) => setFirstName(e.target.value)}
                value={firstName}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col className="h5 mt-1 text-light col-8 col-lg-4">
            <Form.Group className="mb-3">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                id="lastName"
                onChange={(e) => setLastName(e.target.value)}
                value={lastName}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col className="h5 mt-1 text-light col-8 col-lg-4">
            <Form.Group className="mb-3">
              <Form.Label>Whatsapp Number</Form.Label>
              <Form.Control
                type="text"
                id="number"
                onChange={(e) => setNumber(e.target.value)}
                value={number}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col className="h5 mt-1 text-light col-8 col-lg-4">
            <Form.Group className="mb-3">
              <Form.Label>Date Of Birth</Form.Label>
              <Form.Control
                type="date"
                id="dob"
                onChange={(e) => setDob(e.target.value)}
                value={dob}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col className="h5 mt-1 text-light col-8 col-lg-4">
            <Form.Group className="mb-3">
              <Form.Label>Gender</Form.Label>
              <Form.Select
                aria-label="Select"
                id="gender"
                onChange={(e) => setGender(e.target.value)}
                value={gender}
              >
                <option value="0" hidden>
                  Select Gender
                </option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col className="h5 mt-5 text-center text-light col-8 col-lg-4">
            <Button variant="danger" onClick={updateUser()} size="lg">
              <span className="h5">Update</span>
            </Button>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col className="h5 text-center text-dark col-12">
            <small>{status === null ? "Click to update user" : status}</small>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default UpdatePersonal;
