import React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

import Header from "../Header";
import { useAuth } from "../../contexts/MyContext";
import Footer from "../Footer";

import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/esm/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const DashboardOrders = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const { uid, user, setAuthContext, setUser, setUid } = useAuth();

  const handleSubmit = (e) => {
    setAuthContext(null);
    setUser(null);
    setUid(null);
    navigate("/", { replace: true });
  };

  return (
    <div className="bg-black pb-5">
      <Container className="bg-black">
        <Row style={{ height: "55vh" }}>
          <Col className="col-lg-2 mt-5">
            <Row className="p-4 text-light border-danger border rounded ">
              Hello {user} !
            </Row>
            <Row className="p-4 text-light mt-5 border-danger border rounded">
              <Row className="justify-content-center mt-5">
                <Col className="h5 px-1 text-light col-12">
                  <Link
                    className="link-light link-underline-opacity-0"
                    to="/dashboard/personal"
                  >
                    Profile Info
                  </Link>
                </Col>
              </Row>
              <Row className="justify-content-center mt-1">
                <Col className="h5 px-1 text-light col-12">
                  <Link
                    className="link-light link-underline-opacity-0"
                    to="/dashboard/addresses"
                  >
                    Saved Addresses
                  </Link>
                </Col>
              </Row>
              <Row className="justify-content-center mt-1">
                <Col className="h5 px-1 text-danger col-12">
                  <Link
                    className="link-danger link-underline-opacity-0"
                    to="/dashboard/orders"
                  >
                    Your Orders
                  </Link>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col className="h5 mt-5 px-4 text-light col-12">
                  <Button variant="danger" onClick={handleSubmit} size="sm">
                    <span className="h5">LOG OUT</span>
                  </Button>
                </Col>
              </Row>
            </Row>
          </Col>
          <Col className="col-lg-9 m-5 border border-light rounded">
            <Row className="h3 m-3 mt-3 text-danger">Order Information</Row>
            <Row className="h5 mt-5 px-3 text-light">
              <Col>No Orders Found!</Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default DashboardOrders;
