import axios from 'axios'; // Make sure axios is correctly imported


const BASE_URL = 'https://fusion-backend.onrender.com';

export default axios.create({
    baseURL: BASE_URL
});

export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true
});